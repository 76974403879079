






























































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import Util from '@/utils/Util';
import { Icon } from '@/model/util/Icon';
import AppContainerBase from '@/components/util/AppContainerBase.vue';
import AppDialog from '@/components/util/AppDialog.vue';
import { internet } from '@/utils/Internet';
import { IDataTable } from '@/model/main/IDataTable';
import Swal from 'sweetalert2';
@Component({
	name: 'MainAudioLibrary',
	components: {
		AppContainerBase,
		AppDialog
	},
})
export default class MainAudioLibrary extends Vue {
	public title = this.$t("Biblioteca.title");
	public isLoading = false;
	// Dialogs
	public dialogUnAudio = false;
	public titleDialog_1 = this.$t("Biblioteca.dialogTitle1");
	public titleDialog_2 = this.$t("Biblioteca.dialogVariosTitle");
	public textForm_1 = this.$t("Biblioteca.callCenter");
	public textForm_2 = this.$t("Biblioteca.campania");
	public textForm_3 = this.$t("Biblioteca.nombreAgente");
	public textForm_4 = this.$t("Biblioteca.nombreDestinatario");
	public textForm_5 = this.$t("Biblioteca.rut");
	public formDate = this.$t("Biblioteca.fecha");
	public btnCerrar = this.$t("Biblioteca.btnCerrar");
	public btnOk = this.$t("Biblioteca.btnDialog");
	public dialogCarga = this.$t("Biblioteca.dialogCarga");
	// agregar Metadata
	public btnAgregar = this.$t("Biblioteca.btnAgregar");
	public metadataTitle = this.$t("Biblioteca.metadataTitle");
	public thCallCenter = this.$t("Biblioteca.thCallCenter");
	public thCampania = this.$t("Biblioteca.thCampania");
	public thNombreAgente = this.$t("Biblioteca.thNombreAgente");
	public thNombreDestinatario = this.$t("Biblioteca.thNombreDestinatario");
	public thRut = this.$t("Biblioteca.thRut");
	public thFecha = this.$t("Biblioteca.thFecha");
	// Radios
	public radioGroup = this.$t("Biblioteca.radioGroup");
	public radioGroup2 = this.$t("Biblioteca.radioGroup2");
	public radioGroup3 = this.$t("Biblioteca.radioGroup3");
	// Select
	public subtitleSelect = this.$t("Biblioteca.subtitleSelect");
	public fileInput = this.$t("Biblioteca.fileInput");
	public fileInput2 = this.$t("Biblioteca.fileInput2");
	public labelScripts = this.$t("Biblioteca.selectScripts");
	public labelTipoLlamada = this.$t("Biblioteca.selectTipoLlamada");
	public tipoLlamDisabled = true;
	// metadata
	public metadata = this.$t("Biblioteca.selectMetadata");
	public example = this.$t("Biblioteca.example");
	public span = this.$t("Biblioteca.span");
	public agregarMetadata = this.$t("Biblioteca.agregarMetadata");
	public metadata_disabled = false;
	public btn_metadata_disabled = false;
	public tableMetadata = false;
	public tableMetadataCarpeta = false;
	public tableDatosSFTP = false;
	// carga biblioteca
	public thRuta = this.$t("Biblioteca.thRuta");
	public thCantAudios = this.$t("Biblioteca.thCantAudios");
	public thMetadata = this.$t("Biblioteca.thMetadata");
	public btnCarga = this.$t("Biblioteca.btnCarga");
	public titleDatos = this.$t("Biblioteca.titleDatos");
	public fileAudio: any | File = {};
	public listAudio: Array<File> = [];
	public fileText: File | any = {};
	public modal_date = false;
	public radio = 1;
	public dialog_muchos = false;
	public lista_metadata = [];
	public scriptTipoLlam: any = {};
	public scriptSelected = "";
	public tipoLlamSelected = "";
	public selectScript: Array<string> = [];
	public selectTipoLlam: Array<string> = [];
	public cargando = false;
	public nAudio = 0;
	public total = 0;
	public mensajeCarga = "";
	// models metadata
	public value_date = "";
	public valueCallCenter = "";
	public valueCampania = "";
	public valueAgente = "";
	public valueDestinatario = "";
	public valueRut = "";
	// enviar por conjuntos
	public tamanio_conjunto = 20;
	public n_conjunto = 0;
	//timer
	public t_ini = 0;
	public t_conjunto = 0;
	//carpetas sfpt
	public carpetas_sftp = false;
	public lista_carpetas_sftp: Array<any> = [];
	public lista_selector_sftp: Array<string> = [];
	public ruta_sftp_seleccionada = "";
	public carpeta_sftp_seleccionada: any = {};

	mounted() {
		this.obtenerScript();
		this.obtenerArbolSFTP();
	}

	public obtenerArbolSFTP(){
		const request = internet
			.newRequest()
			.get("biblioteca/obtenerDirectorios?cliente=gesticom") //extension=xlsx
			.then((response) => {
				// revisar el response para saber donde viene el archivo
				let data = response.data;
				console.log(data);
				let lista = [];
				for (let item of data) {
					lista.push(item.ruta)
				}
				this.lista_carpetas_sftp = data;
				this.lista_selector_sftp = lista;
			})
			.catch(console.log)
			.finally(() => {
				//this.isLoading = false;
			});
	}

	public actualizarCarpeta() {
		for (let item of this.lista_carpetas_sftp) {
			if (this.ruta_sftp_seleccionada == item.ruta) {
				this.carpeta_sftp_seleccionada = item;
				break
			}
		}
		this.tableDatosSFTP = true;
		console.log(this.carpeta_sftp_seleccionada);
	}

	public registrarRuta() {
		if (this.carpeta_sftp_seleccionada.metadata.length == 0) {
			Util.showMessage(
				'No hay metadata, agregar a la carpeta correspondiente del SFTP',
				Icon.WARNING
			)
		} else {
			this.isLoading = true;
			const request = internet
				.newRequest()
				.post(`biblioteca/iniciarProceso?ruta=${this.ruta_sftp_seleccionada}`)
				.then((response) => {
					console.log(response);
				})
				.catch(console.log)
				.finally(() => {
					this.isLoading = false;
					Util.showMessage(
						'Carpeta cargada con exito',
						Icon.SUCCESS
					)
					// Util.showMessage(
					// 'Error al cargar la carpeta',
					// 	Icon.INFO
					// )
				});
		}
	}

	public enviarMetadata() {
		if (this.fileText) {
			let formData = new FormData();
			formData.append('file', this.fileText);
			const request = internet
				.newRequest()
				.post("biblioteca/analizarMetadata", formData)
				.then((response) => {
					this.lista_metadata = response.data;
					this.btn_metadata_disabled = true;
					console.log(this.lista_metadata);
				})
				.catch(console.log)
				.finally(() => {
					//this.isLoading = false;
				});
		}
	}

	// esto en vez de ir en el audio deberia ir en el btn cargar a la biblioteca 
	public enviarAudio(){
		let cliente = "";
		if (localStorage.getItem("client") != null) {
			cliente = localStorage.getItem("client") as string;
		}
		let formData = new FormData();
		formData.append('file', this.fileAudio);
		formData.append('cliente', cliente);
		formData.append('call_center', this.valueCallCenter);
		formData.append('campania', this.valueCampania);
		formData.append('fecha', this.value_date);
		if (this.valueAgente != "") {
			formData.append('agente', this.valueAgente);
		}
		if (this.valueDestinatario != "") {
			formData.append('destinatario', this.valueDestinatario);
		}
		if (this.valueRut != "") {
			formData.append('rut_destinatario', this.valueRut);
		}
		if (this.scriptSelected != "") {
			formData.append('id_script', this.scriptSelected);
		}
		if (this.tipoLlamSelected!= "") {
			formData.append('tipo_llamada', this.tipoLlamSelected);
		}
		const request = internet
			.newRequest()
			.post("biblioteca/saveAudio", formData)
			.then((response) => {
				//console.log(response.data);
				this.nAudio += 1;
				this.mensajeCarga = `${this.nAudio}/${this.total} audios cargados`;
				if (this.nAudio % this.tamanio_conjunto == 0) {
					let t_actual = Date.now();
					let tiempo = (t_actual - this.t_conjunto) / 1000;
					console.log(`Tiempo: ${tiempo}`);
					console.log("enviando el siguiente conjunto");
					this.enviarConjuntoAudios();
				}
				if (this.nAudio >= this.total) {
					this.total = 0;
					this.nAudio = 0;
					this.n_conjunto = 0;
					this.cargando = false;
					let t_final = Date.now();
					let tiempo = (t_final - this.t_conjunto) / 1000;
					console.log(`Tiempo: ${tiempo}`);
					let tiempo_total = (t_final - this.t_ini) / 1000;
					console.log(`Tiempo Total: ${tiempo_total}`);
					Util.showMessage(
						'Audios almacenados con exito',
						Icon.SUCCESS
					);
				}
			})
			.catch((error) => {
				console.log(error.data);
				this.nAudio += 1;
				this.mensajeCarga = `${this.nAudio}/${this.total} audios cargados`;
				if (this.nAudio % this.tamanio_conjunto == 0) {
					let t_actual = Date.now();
					let tiempo = (t_actual - this.t_conjunto) / 1000;
					console.log(`Tiempo: ${tiempo}`);
					console.log("enviando el siguiente conjunto");
					this.enviarConjuntoAudios();
				}
				if (this.nAudio >= this.total) {
					this.total = 0;
					this.nAudio = 0;
					this.n_conjunto = 0;
					this.cargando = false;
					let t_final = Date.now();
					let tiempo = (t_final - this.t_conjunto) / 1000;
					console.log(`Tiempo: ${tiempo}`);
					let tiempo_total = (t_final - this.t_ini) / 1000;
					console.log(`Tiempo Total: ${tiempo_total}`);
					Util.showMessage(
						'Audios almacenados con exito',
						Icon.SUCCESS
					);
				}
			})
			.finally(() => {
				//this.isLoading = false;
			});
	}

	public enviarVariosAudios(){
		// para la barra de carga segun cuantos audios se vayan cargando
		this.total = this.listAudio.length;
		this.cargando = true;
		this.mensajeCarga = `0/${this.total} audios cargados`;
		for(let file of this.listAudio){
			if (this.lista_metadata.length != 0) {
				let metadata = this.buscarMetadata(file.name);
				this.valueCallCenter = metadata["Call center"];
				this.valueCampania = metadata["Campania"];
				this.value_date = metadata["Fecha (YYYY-MM-DD)"];
				this.valueAgente = metadata["Agente"];
				this.valueDestinatario = metadata["Destinatario"];
				this.valueRut = metadata["Rut destinatario"];
			}
			this.fileAudio = file;
			this.enviarAudio();
		}
	}

	public enviarConjuntoAudios() {
		let ini = this.n_conjunto * this.tamanio_conjunto;
		this.n_conjunto += 1;
		let fin = this.n_conjunto * this.tamanio_conjunto;
		this.t_conjunto = Date.now();
		console.log(`Conjunto (${ini}, ${fin})`)
		for(let file of this.listAudio.slice(ini, fin)) {
			if (this.lista_metadata.length != 0) {
				let metadata = this.buscarMetadata(file.name);
				this.valueCallCenter = metadata["Call center"];
				this.valueCampania = metadata["Campania"];
				this.value_date = metadata["Fecha (YYYY-MM-DD)"];
				this.valueAgente = metadata["Agente"];
				this.valueDestinatario = metadata["Destinatario"];
				this.valueRut = metadata["Rut destinatario"];
			}
			this.fileAudio = file;
			this.enviarAudio();
		}
	}

	public enviarConjuntoAudiosPrueba() {
		let ini = this.n_conjunto * this.tamanio_conjunto;
		this.n_conjunto += 1;
		let fin = this.n_conjunto * this.tamanio_conjunto;
		this.t_conjunto = Date.now();
		console.log(`Conjunto (${ini}, ${fin})`)
		let metadataConjunto = [];
		let audioConjunto = this.listAudio.slice(ini, fin)
		for(let file of audioConjunto) {
			if (this.lista_metadata.length != 0) {
				let metadata = this.buscarMetadata(file.name);
				metadataConjunto.push(metadata)
			} else {
				metadataConjunto.push({
					"Nombre audio": file.name,
					"Agente": "",
					"Rut agente": "",
					"Destinatario": "",
					"Rut destinatario": ""
				})
			}
		}
		this.enviarAudioPrueba(audioConjunto, metadataConjunto);
	}

	public enviarAudioPrueba(audios: any, metadata: any) {
		let cliente = "";
		if (localStorage.getItem("client") != null) {
			cliente = localStorage.getItem("client") as string;
		}
		let formData = new FormData();
		formData.append('cliente', cliente);
		formData.append('call_center', this.valueCallCenter);
		formData.append('campania', this.valueCampania);
		formData.append('fecha', this.value_date);
		if (this.scriptSelected != "") {
			formData.append('id_script', this.scriptSelected);
		}
		if (this.tipoLlamSelected!= "") {
			formData.append('tipo_llamada', this.tipoLlamSelected);
		}
		for (let i = 0; i < audios.length; i++) {
			formData.append('files[]', audios[i]);
			let data = metadata[i];
			formData.append('params[]', `${data["Nombre audio"]}::${data["Agente"]}::${data["Rut agente"]}::${data["Destinatario"]}::${data["Rut destinatario"]}`);
		}

		const request = internet
			.newRequest()
			.post("biblioteca/saveAudioGroup", formData)
			.then((response) => {
				this.nAudio += this.tamanio_conjunto;
				this.mensajeCarga = `${this.nAudio}/${this.total} audios cargados`;
				let t_actual = Date.now();
				let tiempo = (t_actual - this.t_conjunto) / 1000;
				console.log(`Tiempo: ${tiempo}`);
				console.log("enviando el siguiente conjunto");
				if (this.nAudio >= this.total) {
					this.total = 0;
					this.nAudio = 0;
					this.n_conjunto = 0;
					this.cargando = false;
					let t_final = Date.now();
					let tiempo = (t_final - this.t_conjunto) / 1000;
					console.log(`Tiempo: ${tiempo}`);
					let tiempo_total = (t_final - this.t_ini) / 1000;
					console.log(`Tiempo Total: ${tiempo_total}`);
					Util.showMessage(
						'Audios almacenados con exito',
						Icon.SUCCESS
					);
				} else {
					this.enviarConjuntoAudiosPrueba();
				}
			})
			.catch((response) => {
				this.nAudio += this.tamanio_conjunto;
				this.mensajeCarga = `${this.nAudio}/${this.total} audios cargados`;
				let t_actual = Date.now();
				let tiempo = (t_actual - this.t_conjunto) / 1000;
				console.log(`Tiempo: ${tiempo}`);
				console.log("enviando el siguiente conjunto");
				if (this.nAudio >= this.total) {
					this.total = 0;
					this.nAudio = 0;
					this.n_conjunto = 0;
					this.cargando = false;
					let t_final = Date.now();
					let tiempo = (t_final - this.t_conjunto) / 1000;
					console.log(`Tiempo: ${tiempo}`);
					let tiempo_total = (t_final - this.t_ini) / 1000;
					console.log(`Tiempo Total: ${tiempo_total}`);
					Util.showMessage(
						'Audios almacenados con exito',
						Icon.SUCCESS
					);
				} else {
					this.enviarConjuntoAudiosPrueba();
				}
			})
	}

	// este para el btn enviar a la biblioteca 
	public cargarBiblioteca(){
		let audioFile = "";
		if (document.getElementById("audioFile") != null) {
			audioFile = (document.getElementById("audioFile") as HTMLInputElement).value as string;
		}
		//let audioFile = document.getElementById("audioFile") ? document.getElementById("audioFile").value : "";
		if (audioFile === "" && this.listAudio.length === 0) {
			Util.showMessage(
				'No se han agregado audios',
				Icon.WARNING
			);
			return
		}
		if (this.radio == 1){
			if (this.comprobarMetadata()) {
				this.enviarAudio();
			} else {
				Util.showMessage(
					'Faltan metadatos del audio',
					Icon.WARNING
				);
			}
		} else {
			if (this.comprobarMetadataMultiple()) {
				//this.enviarVariosAudios();
				this.total = this.listAudio.length;
				this.cargando = true;
				this.mensajeCarga = `0/${this.total} audios cargados`;
				this.t_ini = Date.now();
				this.enviarConjuntoAudiosPrueba();
			}
		}
	}

	private comprobarMetadata() {
		if (this.lista_metadata.length != 0) {
			for (let item of this.lista_metadata) {
				if (item["Nombre audio"] === this.fileAudio.name) {
					this.valueCallCenter = item["Call center"];
					this.valueCampania = item["Campania"];
					this.value_date = item["Fecha (YYYY-MM-DD)"];
					this.valueAgente = item["Agente"];
					this.valueDestinatario = item["Destinatario"];
					this.valueRut = item["Rut destinatario"];
					return true;
				}
			}
			return false;
		} else if (this.valueCallCenter != "" && this.valueCampania != "" && this.value_date != "") {
			return true;
		} else {
			return false;
		}
	}

	private comprobarMetadataMultiple() {
		let faltantes = [];
		if (this.lista_metadata.length != 0) {
			for(let file of this.listAudio) {
				let encontrado = false;
				for (let item of this.lista_metadata) {
					if (item["Nombre audio"] === file.name) {
						encontrado = true;
						break;
					}
				}
				if (!encontrado) {
					faltantes.push(file.name);
				}
			}
			if (faltantes.length === 0) {
				return true;
			} else {
				let mensaje = faltantes.join("<br>");
				Swal.fire({
					title: undefined,
					icon: Icon.WARNING,
					html: `Faltan los metadatos de algunos audios<br>${mensaje}`,
					toast: false,
					position: 'center',
					showDenyButton: false,
					showConfirmButton: true,
					denyButtonText: 'No',
					confirmButtonText: 'OK',
				});
			}
		} else if (this.valueCallCenter != "" && this.valueCampania != "" && this.value_date != "") {
			return true;
		} else {
			return false;
		}
	}

	private buscarMetadata(audio: string) {
		for(let metadata of this.lista_metadata) {
			if (metadata["Nombre audio"] === audio) {
				return metadata;
			}
		}
		return {
			"Nombre audio": audio,
			"Cliente": "",
			"Call center": "",
			"Campania": "",
			"Fecha (YYYY-MM-DD)": "",
			"Agente": "",
			"Rut agente": "",
			"Destinatario": "",
			"Rut destinatario": ""
		}
	}

		//comprobar si descarga altiro el archivo o pide algo mas para poder descargar
	public ejemploMetadata(){
		const request = internet
			.newRequest()
			.get("biblioteca/getExample?extension=csv") //extension=xlsx
			.then((response) => {
				// revisar el response para saber donde viene el archivo
				let data = response.data;
				let tipo = response.headers['content-type'];
				const blob = new Blob([data], { type: tipo });
				let link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = 'EJEMPLO_METADATA.csv';
				link.click();
			})
			.catch(console.log)
			.finally(() => {
				//this.isLoading = false;
			});
	}
	
	// para llenar select de los scripts
	public obtenerScript(){
		const request = internet
			.newRequest()
			.get("biblioteca/getScriptsTipoLlam") 
			.then((response) => {
				this.scriptTipoLlam = response.data;
				this.selectScript = Object.keys(response.data);
				//console.log("scrip", response)
			})
			.catch(console.log)
			.finally(() => {
				//this.isLoading = false;
			});
	}

	public changeScript() {
		this.selectTipoLlam = this.scriptTipoLlam[this.scriptSelected];
		this.tipoLlamDisabled = false;
	}

	public limpiarModels() {
		this.valueAgente = "";
		this.valueCallCenter = "";
		this.valueCampania = "";
		this.valueDestinatario = "";
		this.valueRut = "";
		this.value_date = "";
		this.btn_metadata_disabled = false;
		this.tableMetadata = false;
		this.tableMetadataCarpeta = false;
		this.tableDatosSFTP = false;
		this.metadata_disabled = false;
		this.fileAudio = {} as File;
		this.listAudio = [];
		this.fileText = {} as File;
		this.lista_metadata = [];
	}

	
	public listenBack() {
		Util.showMessage('Presionado volver', Icon.SUCCESS);
	}


	public get rules() {
		return [(v: any) => !!v || 'Archivo requerido'];
	}
}

